import React, { useState, useEffect } from 'react';
import axios from 'axios';

const TestComponent = () => {
  const [str, setStr] = useState('');

  useEffect(() => {
    const fetchString = async () => {
      try {
        const response = await axios.get('/loverice/hello/sayHello');
        setStr(response.data);
      } catch (error) {
        console.error('Error fetching string:', error);
      }
    };

    fetchString();
  }, []);

  return (
    <div>
      <h2>Fetched String:</h2>
      <p>{str}</p>
    </div>
  );
};

export default TestComponent;